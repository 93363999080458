import DEMO_IMAGE from '../../static/images/DemoImage.svg';
import SAMPLE_APP_IMAGE from '../../static/images/sampleApp.svg';
import WEBINAR_IMAGE from '../../static/images/Webinar.svg';

export const RouteCardItems = [
  {
    title: 'Tokenization Demo',
    description: "See Skyflow's end-to-end tokenization solution in action.",
    link: 'https://tokenization-demo.skyflow.app/',
    image: DEMO_IMAGE,
  },
  {
    title: 'Card Issuer Dashboard Sample App',
    description:
      'Reduce your business’s compliance scope using tokenization with Skyflow.',
    image: SAMPLE_APP_IMAGE,
    link: 'https://creditcard-demo.skyflow.app/',
  },
  {
    title: 'Data Governance Demo',
    description:
      'See how Skyflow can be used to securely share sensitive data with internal and external teams.',
    image: DEMO_IMAGE,
    link: 'https://governance-demo.skyflow.app/',
  },
  {
    title: 'Webinar: Build Fintech Faster',
    description:
      'Learn how Skyflow can help Fintech companies build fast while maintaining top-notch data privacy.',
    image: WEBINAR_IMAGE,
    link: 'https://info.skyflow.com/skyflow-payments-vault-intro?submissionGuid=30fc757e-f87f-49b9-b712-12dbac3f5f93',
  },
];

import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Button, Box, Grid, IconButton, CardMedia } from '@mui/material';
import theme, {
  COLORS,
  BREAKPOINTS,
  MOBILE_SPACING,
  WIDTH_1024_SPACING,
} from '../utils/theme';
import { GuideCardItems } from '../utils/GuideCardItems';
import { RouteCardItems } from '../utils/RouteCardItems';
import GuideCardGroup from '../components/common/GuideCardGroup';
import RouteCardGroup from '../components/common/RouteCardGroup';
import HomePageImage from '../../static/images/homePage.svg';
import LinkWrapper from '../components/common/Link';
import homePageMessages from '../utils/messages/en/homePageMessages';
import externalLinks from '../../static/images/externalLinks.svg';
import Typography from '../components/common/Typography';
import SEO from '../components/common/Seo';
import globalMessages from '../utils/messages/en/globalMessages';
import ROUTES from '../docs-config/src';
const useStyles = {
  outerBox: {
    margin: 'auto',
    maxWidth: '1010px',
    [BREAKPOINTS.DEFAULT]: {
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(10),
      maxWidth: `calc( 100% - ${WIDTH_1024_SPACING.MARGIN} )`,
    },
    [BREAKPOINTS.MOBILE]: {
      paddingTop: '12px',
      margin: 'auto 20px',
      maxWidth: `calc( 100% - ${MOBILE_SPACING.MARGIN} )`,
    },
  },
  leftItem: {
    maxWidth: '352.5px',
    margin: 'auto',
    marginLeft: '0px',
    marginRight: '0px',
    [BREAKPOINTS.CUSTOM]: {
      marginTop: '20px',
      maxWidth: '100%'
    }
  },
  titleStyle: {
    marginBottom: '22px',
    [BREAKPOINTS.MOBILE]: {
      marginBottom: '12px',
    },
  },
  descriptionStyle: {
    marginBottom: '22px',
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
    [BREAKPOINTS.MOBILE]: {
      marginBottom: '32px',
    },
  },
  buttonGroupStyle: {
    display: 'flex',
    alignItems: 'center',
    [BREAKPOINTS.MOBILE]: {
      margin: '32px 0px 0px 0px',
    },
  },
  QuickstartButtonStyle: {
    borderRadius: '100px',
    padding: '8px 20px',
  },
  externalLinkIcon: {
    width: '16px',
    height: '16px',
  },
  homePageImageStyle: {
    width: '593.5px',
    height: '371.7px',
    [BREAKPOINTS.MOBILE]: {
      width: '326px',
      height: '200px',
    },
    [BREAKPOINTS.WIDTH_1024]: {
      height: '337.7px',
      width: '559.5px',
    },
  },
  homeIllustration: {
    [BREAKPOINTS.DESKTOP]: {
      marginLeft: '3%',
    },
    [BREAKPOINTS.CUSTOM]: {
      display: 'flex',
      justifyContent: 'center'
    },
  },
  apiDocsTextStyle: {
    color: theme.palette.primary.main,
  },
  apiDocsStyle: {
    display: 'flex',
    textDecoration: 'underline',
    marginLeft: '20px',
    alignItems: 'center',
  },
  headerStyle: {
    marginTop: '64px',
    color: COLORS.TEXT_HIGH_EMPHASIS,
    [BREAKPOINTS.MOBILE]: {
      marginTop: '32px',
      marginBottom: '0px',
    },
  },
  subTitleStyle: {
    marginTop: '2px',
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
    marginBottom: '24px',
  },
  iconButton: {
    padding: '0px',
    paddingLeft: '8px',
  },
  quickStartStyle: {
    color: theme.palette.common.white,
  },
  gridStyle: {
    justifyContent: 'space-between',
    marginTop: '22px',
    [BREAKPOINTS.CUSTOM]: {
      flexDirection: 'column-reverse',
    },
    [BREAKPOINTS.DESKTOP]: {
      justifyContent: 'flex-start',
    },
  },
};

const IndexPage = () => {
  if (typeof window !== 'undefined') {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }
  return (
    <main>
      <SEO
        title={homePageMessages.welcome}
        description={homePageMessages.description}
      />
      <ThemeProvider theme={theme}>
        <Box sx={useStyles.outerBox}>
          <Grid container sx={useStyles.gridStyle}>
            <Grid item sx={useStyles.leftItem}>
              <Typography variant="h1" sx={useStyles.titleStyle}>
                {homePageMessages.welcome}
              </Typography>
              <Typography
                variant="h3"
                component="p"
                sx={useStyles.descriptionStyle}
              >
                {homePageMessages.description}
              </Typography>
              <Box style={useStyles.buttonGroupStyle}>
                <LinkWrapper href={ROUTES.productGetStarted}>
                  <Button
                    variant="contained"
                    style={useStyles.QuickstartButtonStyle}
                  >
                    <Typography
                      type="DESKTOP_ACTION_PRIMARY"
                      sx={useStyles.quickStartStyle}
                    >
                      {homePageMessages.quickStart}
                    </Typography>
                  </Button>
                </LinkWrapper>
                <Box style={useStyles.apiDocsStyle}>
                  <LinkWrapper
                    href={ROUTES.apiReference.home}
                    isTextDecorationOn={true}
                  >
                    <Typography
                      type="DESKTOP_BODY_LINKS"
                      sx={useStyles.apiDocsTextStyle}
                    >
                      {homePageMessages.apiDocs}
                    </Typography>
                  </LinkWrapper>
                  <IconButton
                    style={useStyles.iconButton}
                    disableRipple
                    onClick={() =>
                      window.open(ROUTES.apiReference.home, '_blank')
                    }
                  >
                    <img
                      src={externalLinks}
                      style={useStyles.externalLinkIcon}
                      alt="external_link"
                    />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
            <Grid item sx={useStyles.homeIllustration}>
              <Box sx={useStyles.homePageImageStyle}>
                <CardMedia
                  component="img"
                  image={HomePageImage}
                  alt={'skyflow'}
                  sx={useStyles.homePageImageStyle}
                />
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Typography variant="h2" sx={useStyles.headerStyle}>
              {homePageMessages.guideTitle}
            </Typography>
            <Typography
              type="DESKTOP_BODY_PARAGRAPH"
              sx={useStyles.subTitleStyle}
            >
              {homePageMessages.guideSubTitle}
            </Typography>
          </Box>
          <GuideCardGroup
            items={GuideCardItems}
            pageTitle={globalMessages.homePage}
          />
          <Typography variant="h2" sx={useStyles.headerStyle}>
            {homePageMessages.routeTitle}
          </Typography>
          <Typography
            type="DESKTOP_BODY_PARAGRAPH"
            sx={useStyles.subTitleStyle}
          >
            {homePageMessages.routeSubTitle}
          </Typography>
          <Box>
            <RouteCardGroup items={RouteCardItems} pageTitle="home" />
          </Box>
        </Box>
      </ThemeProvider>
    </main>
  );
};

export default IndexPage;

import primaryCube from '../../static/images/guideCard1.svg';
import secondaryCube from '../../static/images/guideCard2.svg';
import tertiaryCube from '../../static/images/guideCard3.svg';
import quaternaryCube from '../../static/images/guideCard4.svg';
import ROUTES from '../docs-config/src';


export const GuideCardItems = [
  {
    title: 'Securely store sensitive data in Vault',
    description:
      'Learn the basics of Skyflow’s Data API to read and write sensitive data to a Vault.',
    image: primaryCube,
    link: ROUTES.coreApiQuickstart,
  },
  {
    title: 'Collect sensitive data from the client-side',
    description:
      'Use Skyflow’s client-side SDKs to securely collect sensitive data from the client.',
    image: secondaryCube,
    link: 'https://github.com/skyflowapi/skyflow-js#skyflow-js',
  },
  {
    title: 'Govern access to sensitive data',
    description:
      'Use Skyflow’s data governance capabilities to control access to your sensitive data.',
    image: tertiaryCube,
    link: ROUTES.dataGovernanceQuickstart,
  },
  {
    title: 'Use sensitive data with other APIs',
    description:
      'Use Skyflow Connections to securely send sensitive data to downstream APIs like Stripe, Adyen, etc.',
    image: quaternaryCube,
    link: ROUTES.connectionsQuickstart,
  },
];

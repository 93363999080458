import * as React from 'react';
import { Grid } from '@mui/material';
import GuideCard, { GuideCardProps } from '../GuideCard';
import globalMessages from '../../../utils/messages/en/globalMessages';
import { BREAKPOINTS } from '../../../utils/theme';

const useStyles = {
  cardGroupStyle: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(228.5px,1fr))',
    gridGap: '32px',
    flex: '1',
    flexDirection: 'column',
    [BREAKPOINTS.MOBILE]: {
      gridGap: '16px',
    },
  },
  cardItemStyle: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
};
export interface GuideCardGroupProps {
  items: GuideCardProps[];
  pageTitle?:
    | typeof globalMessages.homePage
    | typeof globalMessages.sdKPage
    | typeof globalMessages.apiReferencePages
    | typeof globalMessages.demoAndSampleAppPage;
}
const GuideCardGroup: React.FC<GuideCardGroupProps> = ({
  items,
  pageTitle,
}) => {
  return (
    <Grid container sx={useStyles.cardGroupStyle}>
      {items.map((card) => 
        <Grid item key={card.title} sx={useStyles.cardItemStyle}>
          <GuideCard {...card} pageTitle={pageTitle} />
        </Grid>
      )}
    </Grid>
  );
};

export default GuideCardGroup;
